
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































/* stylelint-disable no-descending-specificity */
.section-cta-outer {
  margin: $spacing * 2 0;
  padding-bottom: $spacing * 2;
  border-bottom: 1px solid $c-blue-light-pale;

  &.no-border {
    border-bottom: 0;
  }
}

.section-cta__list {
  @extend %list-nostyle;
}

.section-cta {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 0.75 $spacing;
  border: 1px solid $c-blue-light-pale;
  border-bottom: none;
  transition: all 0.3s $ease-out;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid $c-blue-light-pale;
  }

  &:hover {
    @extend %box-shadow;

    z-index: 2;
    color: $white;
    background: $c-anthracite-light;
    transform: scale(1.02, 1.02);

    .section-cta__icon svg,
    .section-cta__content__icon {
      fill: $white;
    }

    .section-cta__content {
      transform: scale(0.98, 0.98);
    }
  }

  @include mq('s') {
    padding: $spacing * 1.25 $spacing * 1.5;
  }
}

.section-cta__icon {
  display: flex;
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  margin-left: 2rem;
  border: 1px solid $c-blue-light-pale;
  border-radius: 50%;

  svg {
    margin: auto;
    fill: $c-pink-medium;
  }
}

.section-cta__content {
  margin-top: -5px;
  font-family: $ff-alt;
  font-weight: 700;

  &.has-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.section-cta__content__title {
  display: inline;
  font-size: 2.5rem;
  line-height: 1.15;
  vertical-align: middle;

  @include mq('l') {
    line-height: 1;
  }
}

.section-cta__content__icon,
.section-cta__content__logo {
  display: inline;
  height: auto;
  margin-right: $spacing;
  vertical-align: middle;
}

.section-cta__content__icon {
  width: 5rem;
}

.section-cta__content__logo {
  transition: all 0.3s $ease-out;
}

.section-cta__content__text {
  padding-top: $spacing * 0.25;
  font-size: 1.8rem;
  line-height: 2rem;

  @include mq('s') {
    padding-top: 0;
  }
}

.section-cta__link {
  @include get-all-space;

  z-index: layer(high);

  &::before,
  &::after {
    display: none;
  }
}

.section-cta__more {
  margin-top: $spacing * 1.5;
  font-size: 1.6rem;
}
